html {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
}

.main-container {
    display: flex;
    flex-flow: column;
    align-items: center;
    margin: 50px 0 0;
}

.select-with-label {
    display: grid;
    grid-template-columns: auto 300px;
    gap: 10px;
    margin: 20px 0;
}

.products-container {
    margin: 20px 0;
}

.products {
    display: grid;
    grid-template-columns: 40px 90px 1fr 60px;
    margin: 30px 0 20px;
    gap: 10px;
}

.products p {
    margin: 0;
}

.plus-minus {
    width: 100px;
    display: flex;
    justify-content: space-between;
}

.plus-minus button {
    width: 45px;
    height: 25px;
}

.quote {
    font-style: italic;
    font-size: 14px;
    color: #8b8b8b;
    margin: 0 0 25px;
}

.merchants-fieldset {
    display: flex;
    flex-direction: column;
    margin: 20px 0;
}

fieldset label {
    padding: 0 5px;
}
